<template>
  <CModal
    :title="$t('create_lock_room')"
    size="xl"
    color="info"
    :closeOnBackdrop="false"
    :show.sync="modal.show"
    @update:show="showUpdate"
  >
    <CRow>
      <CCol col="12" sm="12">
        <CSelect
          :label="$t('room')"
          :options="roomOptions"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          :value.sync="formData.room_id"
          :isValid="valid_formData_room_id"
        />
      </CCol>
      <CCol col="12" sm="12">
        <div class="form-group form-row">
          <label class="col-form-label col-sm-3">
            {{ $t('start_date') }}
          </label>
          <div class="col-sm-9">
            <date-picker
              v-model="formData.start_date"
              :shortcuts="false"
              :editable="false"
              :not-before="$moment(bookingOpenDateOption.first)"
              :not-after="$moment(bookingOpenDateOption.last)"
              width="100%"
              type="date"
              format="YYYY-MM-DD"
              :input-class="['form-control', 'bg-white', valid_formData_start_date ? 'is-valid' : 'is-invalid']"
            />
          </div>
        </div>
      </CCol>
      <CCol col="12" sm="12">
        <div class="form-group form-row">
          <label class="col-form-label col-sm-3">
            {{ $t('end_date') }}
          </label>
          <div class="col-sm-9">
            <date-picker
              v-model="formData.end_date"
              :shortcuts="false"
              :editable="false"
              :not-before="$moment(bookingOpenDateOption.first)"
              :not-after="$moment(bookingOpenDateOption.last)"
              width="100%"
              type="date"
              format="YYYY-MM-DD"
              :input-class="['form-control', 'bg-white', valid_formData_end_date ? 'is-valid' : 'is-invalid']"
            />
          </div>
        </div>
      </CCol>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('quantity')"
          :placeholder="$t('quantity')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          type="number"
          min="1"
          v-model="formData.quantity"
          :isValid="valid_formData_quantity"
        />
      </CCol>
    </CRow>
    <CButton slot="footer" color="primary" block @click="submit()">{{ $t('save') }}</CButton>
  </CModal>
</template>

<script>
export default {
  name: 'CreateRoomLockModal',
  components: {
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      modal: {
        data: this.data,
        show: this.show
      },
      bookingOpenDateOption: {
        first: this.$moment().format('YYYY-MM-DD'),
        last: this.$moment().format('YYYY-MM-DD')
      },
      roomOptions: [
        { value: null, label: this.$t('please_select_a') + this.$t('room') + '...' },
      ],
      formData: {},
    }
  },
  watch: {
    data (e) {
      this.modal.data = e
    },
    show (e) {
      this.modal.show = e
      if (e) {
        this.formData = { room_id: null, start_date: null, end_date: null, quantity: 0 }
      }
    }
  },
  computed: {
    valid_formData_room_id () {
      return this.formData.room_id ? true : false
    },
    valid_formData_start_date () {
      return this.formData.start_date && this.$moment(this.formData.start_date).isValid() && this.$moment(this.formData.end_date).isSameOrAfter(this.formData.start_date) ? true : false
    },
    valid_formData_end_date () {
      return this.formData.end_date && this.$moment(this.formData.end_date).isValid() && this.$moment(this.formData.end_date).isSameOrAfter(this.formData.start_date) ? true : false
    },
    valid_formData_quantity () {
      return this.formData.quantity && this.formData.quantity > 0 ? true : false
    },
    valid_submit () {
      return this.valid_formData_room_id && this.valid_formData_start_date
    }
  },
  mounted: function () {
    this.getBookingOpenDates()
    this.getRooms()
  },
  methods: {
    showUpdate (show, e) {
      this.$emit('showUpdate', show, e)
    },
    formSubmit (submit, e) {
      this.$emit('formSubmit', submit, e)
    },
    getBookingOpenDates () {
      const loader = this.$loading.show()
      this.$store.dispatch('getBookingOpenDates') .then(res => {
        const dates = this.$_.pluck(res, 'booking_date')
        this.bookingOpenDateOption = { first: this.$_.min(dates, date => this.$moment(date).unix()), last: this.$_.max(dates, date => this.$moment(date).unix()) }
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    },
    getRooms () {
      const loader = this.$loading.show()
      this.$store.dispatch('getRooms') .then(res => {
        this.roomOptions = [{ value: null, label: this.$t('please_select_a') + this.$t('room') + '...' }]
        for (const i in res) {
          this.roomOptions.push({ value: res[i].id, label: res[i].name })
        }
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    },
    submit () {
      if (this.valid_submit) {
        const loader = this.$loading.show()
        this.$store.dispatch('storeRoomLock', this.$_.extend(this.$_.clone(this.formData), { start_date: this.$moment(this.formData.start_date).format('YYYY-MM-DD'), end_date: this.$moment(this.formData.end_date).format('YYYY-MM-DD') })).then(res => {
          this.formSubmit(true)
          this.modal.show = false
          this.showUpdate(false)
          this.$swal('Success', this.$t('created_successfully'), 'success')
          loader.hide()
        }).catch(e => {
          this.$swal('Error', e.message ? e.message : e, 'error')
          loader.hide()
        })
      } else {
        this.$swal('Warning', this.$t('required_fields_cannot_be_empty'), 'warning')
      }
    },
  },
  beforeDestroy: function () {
  },
}
</script>
