<template>
  <CRow>
    <CCol col="12" sm="12">
      <CCard>
        <CCardHeader>
          {{ $route.name }}
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol col="12" sm="10">
              <date-picker
                v-model="searchData.date_range"
                :shortcuts="false"
                :editable="false"
                :clearable="false"
                range-separator="~"
                range
                width="100%"
                value-type="date"
                format="YYYY-MM-DD"
              />
            </CCol>
            <CCol col="12" sm="2">
              <CButton color="primary" block @click="getRoomLocks()">{{ $t('search') }}</CButton>
            </CCol>
          </CRow>
          <hr>
          <CRow>
            <CCol col="12" sm="10" class="d-flex align-items-center">
              <font class="text-muted">{{ $t('search_info', searchInfo) }}</font>
            </CCol>
            <CCol col="12" sm="2">
              <CButton color="info" block @click="() => createRoomLockModal = { modal: true }">{{ $t('create_lock_room') }}</CButton>
            </CCol>
          </CRow>
          <hr>
          <v-client-table :columns="columns" :data="data" :options="options">
            <font slot="start_date" slot-scope="props">
              {{ $moment(props.row.start_date).format('YYYY-MM-DD') }}
            </font>
            <font slot="end_date" slot-scope="props">
              {{ $moment(props.row.end_date).format('YYYY-MM-DD') }}
            </font>
            <div slot="action" slot-scope="props" class="text-center">
              <CButtonGroup>
                <CButton color="danger" v-bind="{ variant: 'outline' }" @click="() => deleteRoomLockModal = { data: $_.extend(props.row, { name: props.row.room.name }), modal: true }">{{ $t('delete') }}</CButton>
              </CButtonGroup>
            </div>
          </v-client-table>
        </CCardBody>
      </CCard>

      <CreateRoomLockModal :data="createRoomLockModal.data" :show="createRoomLockModal.modal" @showUpdate="show => createRoomLockModal.modal = show" @formSubmit="getRoomLocks()" />

      <DeleteRoomLockModal :data="deleteRoomLockModal.data" :show="deleteRoomLockModal.modal" @showUpdate="show => deleteRoomLockModal.modal = show" @formSubmit="getRoomLocks()" />

    </CCol>
  </CRow>
</template>

<script>
import CreateRoomLockModal from './modal/CreateRoomLockModal'
import DeleteRoomLockModal from './modal/DeleteRoomLockModal'

export default {
  name: 'lockRoomSetting',
  components: {
    CreateRoomLockModal,
    DeleteRoomLockModal,
  },
  data () {
    return {
      columns: [ 'room.name', 'start_date', 'end_date', 'quantity', 'action' ],
      data: [],
      options: {
        headings: {
          'room.name': this.$t('room'),
          start_date: this.$t('start_date'),
          end_date: this.$t('end_date'),
          quantity: this.$t('quantity'),
          action: this.$t('action')
        },
        sortable: [ 'room.name', 'start_date', 'end_date', 'quantity' ],
        filterable: [ 'room.name', 'start_date', 'end_date', 'quantity' ]
      },
      searchInfo: { at: '-', count: 0, sec: 0 },
      searchData: { date_range: [this.$moment({hour: 0}).subtract(30, 'd'), this.$moment({hour: 0}).add(30, 'd')] },
      createRoomLockModal: { data: {}, modal: false },
      deleteRoomLockModal: { data: {}, modal: false },
    }
  },
  computed: {
  },
  mounted: function () {
    this.getRoomLocks()
  },
  methods: {
    getRoomLocks () {
      const startMoment = this.$moment()
      const loader = this.$loading.show()
      this.$store.dispatch('getRoomLocks', this.$_.clone(this.searchData)).then(res => {
        this.data = res
        this.searchInfo.at = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        this.searchInfo.count = res.length
        this.searchInfo.sec = this.$moment().diff(startMoment) / 1000
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    }
  }
}
</script>
